/* You can add global styles to this file, and also import other style files */
.text-input-container > div {
  margin-right: 0 !important;
  padding-right: 10px !important;
}
.text-input-container > div > input {
  width: 100%;
  max-height: 34px;
}
.text-input-container > div:last-child {
  padding-right: 0 !important;
}
@media (max-width: 992px) {
  .text-input-container > div:nth-child(2) {
    padding-right: 0 !important;
  }
  .text-input-container > div:nth-child(0n + 2) {
    padding-bottom: 10px !important;
  }
}
@media (max-width: 768px) {
  .text-input-container > div:nth-child(odd) {
    padding-right: 0 !important;
    padding-bottom: 10px !important;
  }
}

.icon-example-left:before {
  font-family: "ChevronIcons", Arial, sans-serif;
  content: "\e602";
}
.icon-example-right:before {
  font-family: "ChevronIcons", Arial, sans-serif;
  content: "\e603";
}

#site-specific-nav .mega-menu a {
  display: inline;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  font-family: "GothamNarrowBook", Arial, sans-serif;
  text-decoration: none !important;
  border-bottom: 1px solid transparent;
}
#site-specific-nav .mega-menu a:hover {
  color: #fff;
  border-bottom-color: #fff !important;
}
#site-specific-nav .mega-menu h3 {
  color: #fff;
  margin: 0;
}
#site-specific-nav .mega-menu ul {
  margin-top: 10px;
}
#site-specific-nav .mega-menu li {
  position: relative;
  list-style-type: none;
  padding: 0 0 0 15px;
  line-height: 25px;
  margin: 0;
  text-align: left;
}
#site-specific-nav .mega-menu ul.arrows li:before {
  position: absolute;
  top: 4px;
  left: 0;
  font-family: "ChevronIcons", Arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  content: "\e617";
  color: #fff;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}

fieldset {
  margin-bottom: 1em !important;
  border: 1px solid #666 !important;
  padding: 1px !important;
}

legend {
  padding: 1px 10px !important;
  float: none;
  width: auto;
}

.disabled {
  color: gray;
}

select.no-brand {
  border: 1px solid blue;
}

.chart-container {
  margin: auto;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validation-summary {
  color: #721c24;
  background-color: #f8d7da;
  position: relative;
  padding: 0.1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
}

input.ng-invalid.ng-dirty {
  border: red 1px solid;
}

select.ng-invalid.ng-touched {
  border: red 1px solid;
}

select.ng-invalid.ng-dirty {
  border: red 1px solid;
}

input[type="radio"].ng-invalid.ng-dirty {
  outline-style: auto;
  outline-color: red;
  outline-offset: -1.5px;
}

.mat-step-label.mat-step-label {
  text-overflow: inherit;
  white-space: normal;
}

.validation-message {
  margin: 5px 0px;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.center-content {
  margin: auto;
  text-align: center;
}

.others__submenu {
  background-color: #0B2D71 !important;
  font-family: 'GothamBold', Arial, sans-serif;
  width: 400px;
  font-size: 14px;
}

.others__submenu_item {
  color: #FFFFFF !important;
}
